<template>
    <div class="hero">
        <div class="hero-body pt-3 px-0">

            <login-form />

        </div>
    </div>
</template>

<script>
    import LoginForm from "@/components/Auth/LoginForm";

    export default {
        name: "Login",
        title(){ return this.$t('Login');},
        components: {LoginForm},
        created() {
            this.$Progress.finish();
        }
    };
</script>

<style scoped>

</style>